import { useEffect, useState } from 'react'
import { Drawer } from '../../Drawer/Drawer'
import { User } from '../Header'
import { SpoonflowerLogo } from '../Logo/SpoonflowerLogo'
import { ChevronLeftIcon } from './../../../theme/icons/ChevronLeftIcon'
import { ChevronRightIcon } from './../../../theme/icons/ChevronRightIcon'
import { CloseIcon } from './../../../theme/icons/CloseIcon'
import { LoginIcon } from './../../../theme/icons/LoginIcon'
import { MenuLink } from './Menu'
import styles from './Menu.module.scss'
import { mainMenu } from './menus/main'

type MobileMenuProps = {
  isOpen: boolean
  user: User
  toggleDrawer: () => void
  recentlyViewedUrl: string
}

export function MobileMenu({
  toggleDrawer,
  isOpen,
  user,
  recentlyViewedUrl,
}: MobileMenuProps) {
  const [menuStack, setMenuStack] = useState<MenuLink[][]>([mainMenu])
  const [titleStack, setTitleStack] = useState<string[]>([])
  const displayLogin = !user?.screen_name && titleStack.length < 1

  const currentMenu = menuStack[menuStack.length - 1]
  const isMain = currentMenu === mainMenu

  const handleItemClick = (item: MenuLink) => {
    if (item.childrenItems) {
      setMenuStack([...menuStack, item.childrenItems])
      setTitleStack([...titleStack, item.key])
    }
  }

  const handleBackClick = () => {
    if (menuStack.length > 1) {
      setMenuStack(menuStack.slice(0, -1))
      setTitleStack(titleStack.slice(0, -1))
    }
  }

  const [isBrowser, setIsBrowser] = useState(false)
  useEffect(() => {
    setIsBrowser(true)
  }, [])

  return (
    <Drawer
      isOpen={isOpen}
      toggleDrawer={toggleDrawer}
      left
      onClose={() => {
        setMenuStack([mainMenu])
        setTitleStack([])
      }}
    >
      <ul className={styles.menu}>
        <li className={`${styles.status} ${isMain ? styles.main : ''}`}>
          {isMain ? (
            <SpoonflowerLogo mobile />
          ) : (
            <button
              className={styles.back}
              onClick={handleBackClick}
              tabIndex={0}
            >
              <ChevronLeftIcon />
              <span>{titleStack[titleStack.length - 1]}</span>
            </button>
          )}
          <button
            aria-label='Close menu'
            onClick={() => {
              setTitleStack([])
              setMenuStack([mainMenu])
              toggleDrawer()
            }}
            id='drawerCloseButton'
            className={styles.closeButton}
          >
            <CloseIcon width={14} height={14} />
          </button>
        </li>
        {currentMenu.map((item: MenuLink) => {
          const sanitizedKey = item.key.replace(/[^a-zA-Z0-9]/g, '')
          return item.childrenItems ? (
            <li key={item.key} id={`${sanitizedKey}-menu`}>
              {isBrowser ? (
                <button
                  onClick={() => handleItemClick(item)}
                  className={styles.subMenu}
                >
                  <span>{item.key}</span>
                  <ChevronRightIcon />
                </button>
              ) : (
                <a href={item.url} onClick={() => handleItemClick(item)}>
                  {item.key}
                  <ChevronRightIcon />
                </a>
              )}
              <ul
                style={{ display: 'none' }}
                aria-labelledby={`${sanitizedKey}-menu`}
              >
                {item.childrenItems.map((childItem: MenuLink) => (
                  <li key={childItem.key}>
                    <a href={childItem.url} className={styles.link}>
                      {childItem.key}
                    </a>
                  </li>
                ))}
              </ul>
            </li>
          ) : (
            <li key={item.key}>
              <a href={item.url} className={styles.link}>
                {item.key}
                {item.isNew && <span className={styles.itemLabel}>New</span>}
              </a>
            </li>
          )
        })}
        {menuStack.length < 2 && (
          <li>
            <a href={recentlyViewedUrl} className={styles.link}>
              Recently Viewed
            </a>
          </li>
        )}
        {isMain && !user?.screen_name && (
          <li className={styles.login}>
            <span>
              <a href={'/login'}>Log In</a> or{' '}
              <a href={'/register/new'}>Sign Up</a>
            </span>
            <LoginIcon />
          </li>
        )}
      </ul>
    </Drawer>
  )
}
