'use client'

import * as React from 'react'
import { HTMLAttributes, useEffect, useRef } from 'react'
import { cs } from '../../utils/style.util'
import styles from './Drawer.module.scss'

type DrawerProps = {
  isOpen: boolean
  toggleDrawer: () => void
  children: React.ReactNode
  onClose?: () => void
  left?: boolean
} & HTMLAttributes<HTMLDivElement>

export function Drawer({
  isOpen,
  toggleDrawer,
  children,
  onClose,
  left,
  className,
  ...props
}: DrawerProps) {
  const drawerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isOpen && drawerRef.current) {
      document.body.classList.add(styles.noScroll)
      setTimeout(() => {
        // Selecting the close button by its ID
        const closeButton = drawerRef?.current?.querySelector(
          '#drawerCloseButton'
        ) as HTMLElement
        if (closeButton) {
          closeButton.focus()
        }
      }, 300)
    } else {
      document.body.classList.remove(styles.noScroll)
      onClose?.()
    }
    return () => {
      document.body.classList.remove(styles.noScroll)
    }
  }, [isOpen])

  return (
    <>
      <div
        className={cs(styles.overlay, isOpen && styles.visible)}
        onClick={toggleDrawer}
      ></div>
      <div
        ref={drawerRef}
        className={cs(
          className,
          styles.drawer,
          isOpen && styles.open,
          left && styles.left
        )}
        {...props}
      >
        {isOpen && children}
      </div>
    </>
  )
}
