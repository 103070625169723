import React from 'react'
import { IconProps } from '.'

export function CommercialGradeIcon({
  width = 32,
  height = 32,
  color = '#536063',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 52 52'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        className='st0'
        fill={color}
        d='M3.6,51.5c-.9,0-1.6-.3-2.2-.9-.6-.6-.9-1.3-.9-2.2V15.6c0-.9.3-1.6.9-2.2.6-.6,1.3-.9,2.2-.9h8.9V3.6c0-.9.3-1.6.9-2.2.6-.6,1.3-.9,2.2-.9h20.8c.9,0,1.6.3,2.2.9.6.6.9,1.3.9,2.2v20.9h8.9c.9,0,1.6.3,2.2.9.6.6.9,1.3.9,2.2v20.8c0,.9-.3,1.6-.9,2.2-.6.6-1.3.9-2.2.9h-17.9v-12h-9v12H3.6ZM3.5,48.5h9v-9H3.5v9ZM3.5,36.5h9v-9H3.5v9ZM3.5,24.5h9v-9H3.5v9ZM15.5,36.5h9v-9h-9v9ZM15.5,24.5h9v-9h-9v9ZM15.5,12.5h9V3.5h-9v9ZM27.5,36.5h9v-9h-9v9ZM27.5,24.5h9v-9h-9v9ZM27.5,12.5h9V3.5h-9v9ZM39.5,48.5h9v-9h-9v9ZM39.5,36.5h9v-9h-9v9Z'
      />
    </svg>
  )
}
