import React from 'react'
import { IconProps } from '.'

export function HPGreenGuardIcon({
  width = 64,
  height = 64,
  color = '#536063',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 88 88'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M22.4 69C21.7568 69 21.196 68.7677 20.7176 68.3031C20.2392 67.8388 20 67.2687 20 66.5926C20 65.9474 20.2392 65.3849 20.7176 64.905C21.196 64.4251 21.7568 64.1852 22.4 64.1852H65.6C66.2432 64.1852 66.804 64.4175 67.2824 64.8821C67.7608 65.3464 68 65.9165 68 66.5926C68 67.2378 67.7608 67.8003 67.2824 68.2802C66.804 68.7601 66.2432 69 65.6 69H22.4ZM48.809 40.8057L42.569 34.5464L33.2462 43.898C32.9694 44.176 32.831 44.5157 32.831 44.9169C32.831 45.3182 32.9694 45.6576 33.2462 45.9353L37.4414 50.1572C37.7186 50.4353 38.0572 50.5743 38.4572 50.5743C38.8572 50.5743 39.1956 50.4353 39.4724 50.1572L48.809 40.8057ZM44.2814 32.8426L50.5076 39.088L61.4 28.1758C61.6768 27.8982 61.8152 27.5433 61.8152 27.1112C61.8152 26.679 61.6768 26.3241 61.4 26.0465L57.2828 21.9166C57.006 21.6389 56.6522 21.5001 56.2214 21.5001C55.7906 21.5001 55.4368 21.6389 55.16 21.9166L44.2814 32.8426ZM41.7338 31.9771L51.371 41.6435L41.1848 51.8749C40.4092 52.6529 39.5022 53.0419 38.4638 53.0419C37.4254 53.0419 36.5184 52.6529 35.7428 51.8749L35.2814 51.4121L33.6428 53.0419C33.2952 53.3721 32.8846 53.6375 32.411 53.8382C31.937 54.0388 31.4508 54.1391 30.9524 54.1391H28.2014C27.7646 54.1391 27.467 53.9369 27.3086 53.5324C27.1502 53.128 27.2248 52.7715 27.5324 52.4629L31.9448 48.0508L31.5752 47.6806C30.8 46.9026 30.4078 45.9882 30.3986 44.9374C30.3894 43.8866 30.7724 42.9723 31.5476 42.1947L41.7338 31.9771ZM41.7338 31.9771L53.5076 20.167C54.2832 19.389 55.1902 19 56.2286 19C57.267 19 58.1738 19.389 58.949 20.167L63.1586 24.3751C63.9338 25.1527 64.3214 26.0623 64.3214 27.1039C64.3214 28.1459 63.9338 29.0557 63.1586 29.8333L51.371 41.6435L41.7338 31.9771Z'
        fill={color}
      />
    </svg>
  )
}
