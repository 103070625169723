'use client'
/* eslint-disable import/order */
import './../../theme/_main.scss'
/* eslint-enable import/order */
import { useState } from 'react'
import packageJson from '../../../package.json'
import { Announcement } from './../../index'
import { HamburgerIcon } from './../../theme/icons/HamburgerIcon'
import { Annoucements } from './Announcements/Announcements'
import styles from './Header.module.scss'
import { SpoonflowerLogo } from './Logo/SpoonflowerLogo'
import { Menu, MenuLink } from './Menu/Menu'
import { mainMenu } from './Menu/menus/main'
import { MobileMenu } from './Menu/MobileMenu'
import { RecentlyViewed } from './Menu/RecentlyViewed/RecentlyViewed'
import { Search } from './Search/Search'
import { UserNavigation } from './UserNavigation/UserNavigation'
export interface User {
  screen_name?: string
  id: number
  messages: number
  isAdmin: boolean
  order_id_pending: number
  isLoading: boolean
  stayLegacyCart: boolean
}

export interface HeaderProps {
  user?: User
  announcements?: Announcement[]
  cartCount: number
  locale?: string
  recentlyViewedUrl?: string
  cartUrl?: string
}

export function Header({
  user,
  announcements,
  cartCount,
  locale = '/en',
  recentlyViewedUrl = 'https://www.spoonflower.com/en/recently-viewed',
  cartUrl = 'https://cart.spoonflower.com',
}: HeaderProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen)

  return (
    <header className={styles.wrapper} data-ui-version={packageJson?.version}>
      <a
        href={`${locale}/accessibility-statement`}
        className={styles.visuallyHidden}
      >
        {'Accessibility Statement'}
      </a>
      <Annoucements
        announcements={announcements as Announcement[]}
        cartCount={cartCount}
        user={user as User}
        cartUrl={cartUrl}
      />
      <div className={styles.topWrapper}>
        <SpoonflowerLogo />
        <Search locale={locale} />
        <RecentlyViewed url={recentlyViewedUrl} />
      </div>
      <div className={styles.bottomWrapper}>
        <div className={styles.hamburgerWrapper}>
          <button
            aria-label='open navigation menu'
            data-testid='hamburger-menu'
            className={styles.hamburger}
            onClick={toggleDrawer}
          >
            <HamburgerIcon />
          </button>
        </div>
        <div className={styles.userNav}>
          <UserNavigation
            cartCount={cartCount}
            user={user as User}
            cartUrl={cartUrl}
          />
        </div>
      </div>
      <div className={styles.navWrapper}>
        <nav aria-label='main menu' className={styles.listWrapper}>
          <ul className={styles.navList}>
            {mainMenu.map((item: MenuLink) => (
              <Menu
                key={item.key}
                title={item.key}
                childrenItems={item.childrenItems as MenuLink[]}
                url={item.url as string}
              />
            ))}
          </ul>
          <div className={styles.navList}>
            <MobileMenu
              isOpen={isDrawerOpen}
              toggleDrawer={toggleDrawer}
              user={user as User}
              recentlyViewedUrl={recentlyViewedUrl}
            />
          </div>
        </nav>
      </div>
      <div className={styles.searchWrapper}>
        <Search locale={locale} mobile />
      </div>
    </header>
  )
}
