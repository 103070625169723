'use client'
import { ChatLauncherIcon } from '../../../theme/icons/ChatLauncherIcon'
import styles from './ZendeskLauncher.module.scss'

export function handleZendesk() {
  if (typeof window.zE === 'function') {
    window.zE('messenger', 'open')
  } else {
    console.error('window properties not available.')
  }
}

export const ZendeskLauncher = () => {
  return (
    <>
      <button
        onClick={handleZendesk}
        data-testid='button-launcher'
        className={styles.launcherButton}
        aria-haspopup='dialog'
        aria-controls='composer-input' // closest ID to the chat input
        aria-expanded='false'
      >
        <ChatLauncherIcon aria-label='Help' className='chatLauncherIcon' />
      </button>
    </>
  )
}
