import React from 'react'
import { IconProps } from '.'

export function CloseCircleIcon({
  width = 20,
  height = 20,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 18 18'
      focusable='false'
      aria-hidden
      role='img'
      {...props}
    >
      <path
        d='M9.00033 0.666626C4.39199 0.666626 0.666992 4.39163 0.666992 8.99996C0.666992 13.6083 4.39199 17.3333 9.00033 17.3333C13.6087 17.3333 17.3337 13.6083 17.3337 8.99996C17.3337 4.39163 13.6087 0.666626 9.00033 0.666626ZM12.5837 12.5833C12.2587 12.9083 11.7337 12.9083 11.4087 12.5833L9.00033 10.175L6.59199 12.5833C6.26699 12.9083 5.74199 12.9083 5.41699 12.5833C5.09199 12.2583 5.09199 11.7333 5.41699 11.4083L7.82533 8.99996L5.41699 6.59163C5.09199 6.26663 5.09199 5.74163 5.41699 5.41663C5.74199 5.09163 6.26699 5.09163 6.59199 5.41663L9.00033 7.82496L11.4087 5.41663C11.7337 5.09163 12.2587 5.09163 12.5837 5.41663C12.9087 5.74163 12.9087 6.26663 12.5837 6.59163L10.1753 8.99996L12.5837 11.4083C12.9003 11.725 12.9003 12.2583 12.5837 12.5833Z'
        fill={color}
      />
    </svg>
  )
}
