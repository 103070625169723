import { Slot } from 'radix-ui'

import { forwardRef, HTMLAttributes, isValidElement } from 'react'
import { cs } from '../../utils'

import styles from './Skeleton.module.scss'

type SkeletonElement = React.ElementRef<'span'>
export type SkeletonProps = HTMLAttributes<HTMLSpanElement> & {
  loading?: boolean
  width?: string | number
  height?: string | number
}
const Skeleton = forwardRef<SkeletonElement, SkeletonProps>(
  (passedProps, forwardedRef) => {
    const { children, className, loading = true, ...props } = passedProps

    if (!loading) return children

    const Tag = isValidElement(children) ? Slot.Root : 'span'

    return (
      <Tag
        ref={forwardedRef}
        aria-hidden
        className={cs(styles.skeleton, className)}
        data-inline-skeleton={isValidElement(children) ? undefined : true}
        tabIndex={-1}
        style={{ width: props.width, height: props.height }}
        //@ts-ignore
        inert={true}
        {...props}
      >
        {children}
      </Tag>
    )
  }
)
Skeleton.displayName = 'Skeleton'

export { Skeleton }
