import { Dialog as DialogPrimitive } from 'radix-ui'
import { forwardRef, LegacyRef, ReactNode } from 'react'
import { CloseIcon } from '../../theme/icons'
import styles from './Dialog.module.scss'
export interface DialogContentProps {
  children: ReactNode
  [key: string]: any
}
export const DialogContent = forwardRef(
  (
    { children, ...props }: DialogContentProps,
    forwardedRef: LegacyRef<HTMLDivElement>
  ) => (
    <DialogPrimitive.Portal>
      <DialogPrimitive.Overlay className={styles.dialogOverlay} />
      <DialogPrimitive.Content
        className={styles.dialogContent}
        {...props}
        ref={forwardedRef}
      >
        {children}
        <DialogPrimitive.Close
          aria-label='Close'
          className={styles.dialogClose}
          onClick={(event) => event.stopPropagation()}
        >
          <CloseIcon color={'#fefefe'} />
        </DialogPrimitive.Close>
      </DialogPrimitive.Content>
    </DialogPrimitive.Portal>
  )
)
export const Dialog = DialogPrimitive.Root
export const DialogTrigger = DialogPrimitive.Trigger

export const DialogBase = DialogPrimitive
