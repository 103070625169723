import { MenuLink } from '../Menu'

export const style: MenuLink[] = [
  {
    key: 'All Styles',
    url: '/en/inspiration/styles',
  },
  {
    key: 'Bohemian',
    url: '/en/inspiration/styles/bohemian/bohemian-wallpaper',
  },
  {
    key: 'Cabincore',
    url: '/en/inspiration/styles/cabincore/cabincore-wallpaper',
  },
  {
    key: 'Colorful',
    url: '/en/inspiration/styles/colorful/colorful-wallpaper',
  },
  {
    key: 'English Country',
    url: '/en/inspiration/styles/english-country/english-country-wallpaper',
    isNew: true,
  },
  {
    key: 'French Country',
    url: '/en/inspiration/styles/french-country/french-country-wallpaper',
  },
  {
    key: 'Glam',
    url: '/en/inspiration/styles/glam/glam-wallpaper',
  },
  {
    key: 'Heritage Revival',
    url: '/en/inspiration/styles/heritage-revival/heritage-revival-wallpaper',
  },
  {
    key: 'Japandi Farmhouse',
    url: '/en/inspiration/styles/japandi/japandi-wallpaper',
  },
  {
    key: 'Modern',
    url: '/en/inspiration/styles/modern/modern-wallpaper',
  },
  {
    key: 'Southern Maximalism',
    url: '/en/inspiration/styles/southern-maximalism/southern-maximalism-wallpaper',
    isNew: true,
  },
  {
    key: 'Western',
    url: '/en/inspiration/styles/western/western-wallpaper',
    isNew: true,
  },
]
