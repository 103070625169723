import { IconProps } from '.'

export function WinnerIcon({
  width = 14,
  height = 18,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      aria-hidden
      role='img'
      fill={color}
      width={width}
      height={height}
      viewBox='0 0 14 18'
      {...props}
    >
      <path d='M6.10004 9.36922L7.00004 8.68589L7.89171 9.36089C8.21671 9.60256 8.65837 9.29422 8.54171 8.90256L8.19171 7.76922L9.19171 6.97756C9.50004 6.74422 9.32504 6.24422 8.92504 6.24422H7.75837L7.40004 5.12756C7.27504 4.74422 6.73337 4.74422 6.60837 5.12756L6.24171 6.24422H5.06671C4.67504 6.24422 4.50004 6.74422 4.80837 6.98589L5.80004 7.77756L5.45004 8.91089C5.33337 9.30256 5.77504 9.61089 6.10004 9.36922ZM2.00004 16.7526C2.00004 17.3192 2.55837 17.7192 3.10004 17.5442L7.00004 16.2442L10.9 17.5442C11.4417 17.7276 12 17.3276 12 16.7526V11.4776C13.0334 10.3026 13.6667 8.76922 13.6667 7.07756C13.6667 3.39422 10.6834 0.410889 7.00004 0.410889C3.31671 0.410889 0.333374 3.39422 0.333374 7.07756C0.333374 8.76922 0.966707 10.3026 2.00004 11.4776V16.7526ZM7.00004 2.07756C9.75837 2.07756 12 4.31922 12 7.07756C12 9.83589 9.75837 12.0776 7.00004 12.0776C4.24171 12.0776 2.00004 9.83589 2.00004 7.07756C2.00004 4.31922 4.24171 2.07756 7.00004 2.07756Z' />
    </svg>
  )
}
