'use client'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import styles from './Dropdown.module.scss'

interface DropdownProps {
  buttonLabel: React.ReactNode
  ariaLabel?: string
  buttonStyle?: string
  dropdownStyle?: string
  children: ReactNode
  id: string
  onClose?: () => void
}

export function Dropdown({
  buttonLabel,
  ariaLabel,
  buttonStyle,
  dropdownStyle,
  children,
  id,
  onClose,
}: DropdownProps) {
  const [isOpen, setIsOpen] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dropdownRef = useRef<HTMLUListElement>(null)
  const [dropdownPosition, setDropdownPosition] = useState('right')

  const toggleDropdown = useCallback(() => {
    if (isOpen) {
      setIsAnimating(true)
      setTimeout(() => {
        setIsOpen(false)
        setIsAnimating(false)
        onClose?.()
      }, 200)
    } else {
      setIsOpen(true)
    }
  }, [isOpen])

  const closeDropdown = useCallback(() => {
    setIsAnimating(true)
    setTimeout(() => {
      setIsOpen(false)
      setIsAnimating(false)
      onClose?.()
    }, 200)
  }, [])

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event.key === 'Escape') {
        closeDropdown()
        buttonRef.current?.focus()
      }
    },
    [closeDropdown]
  )

  const handleChildClick = useCallback(
    (e: React.MouseEvent) => {
      let target = e.target as HTMLElement | null
      while (target && target !== e.currentTarget) {
        if (target.tagName === 'A') {
          toggleDropdown()
          break
        }
        target = target.parentNode as HTMLElement | null
      }
    },
    [toggleDropdown]
  )

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        buttonRef.current &&
        !buttonRef.current.contains(event.target as Node)
      ) {
        closeDropdown()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('focusin', handleClickOutside as EventListener)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener(
        'focusin',
        handleClickOutside as EventListener
      )
    }
  }, [closeDropdown])

  useEffect(() => {
    const handleResize = () => {
      if (dropdownRef.current) {
        const dropdownRect = dropdownRef.current.getBoundingClientRect()
        const viewportWidth = window.innerWidth

        const spaceOnRight = viewportWidth - dropdownRect.right
        const spaceNeeded = 200

        setDropdownPosition(spaceOnRight < spaceNeeded ? 'left' : 'right')
      }
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [isOpen])

  return (
    <div className={styles.dropdownContainer}>
      <button
        ref={buttonRef}
        className={buttonStyle}
        aria-expanded={isOpen}
        aria-controls={isOpen ? id : undefined}
        {...(ariaLabel ? { 'aria-label': ariaLabel } : {})}
        onClick={toggleDropdown}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()
            toggleDropdown()
          }
        }}
      >
        {buttonLabel}
      </button>
      {isOpen && (
        <ul
          id={id}
          ref={dropdownRef}
          className={`${styles.dropdown} ${isOpen ? styles.open : ''} ${
            isAnimating && !isOpen ? styles.close : ''
          } ${dropdownStyle} ${styles.dropdownPositioning} ${
            dropdownPosition === 'left' ? styles.left : ''
          }`}
          role='menu'
          tabIndex={-1}
          onKeyDown={handleKeyDown}
          onClick={handleChildClick}
        >
          {children}
        </ul>
      )}
    </div>
  )
}
