import { Toast } from 'radix-ui'
import { ReactNode } from 'react'
import styles from './UIProvider.module.scss'

export interface UIProviderProps {
  children: ReactNode
}

export const UIProvider = ({ children }: UIProviderProps) => {
  return (
    <Toast.Provider swipeDirection='up'>
      <Toast.Viewport className={styles.toastViewport} />
      {children}
    </Toast.Provider>
  )
}
