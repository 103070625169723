import { Toast as ToastPrimitive } from 'radix-ui'
import { CloseIcon } from '../../theme/icons'
import { cs } from '../../utils/style.util'
import styles from './Toast.module.scss'

export interface ToastProps {
  open: boolean
  onOpenChange: (open: boolean) => void
  title?: string
  content?: string
  children?: React.ReactNode
  variant?: 'success' | 'error' | 'warning' | 'info'
  duration?: number
  closeButton?: boolean
  dataTrackingAttribute?: string
}

export const Toast = ({
  title,
  content,
  children,
  variant = 'success',
  duration = 5000,
  closeButton,
  dataTrackingAttribute,
  ...props
}: ToastProps) => {
  return (
    <ToastPrimitive.Root
      className={cs(styles.toastRoot, styles[variant])}
      data-tracking-attribute={dataTrackingAttribute}
      {...props}
    >
      {title && (
        <ToastPrimitive.Title className={styles.toastTitle}>
          {title}
        </ToastPrimitive.Title>
      )}
      {content && (
        <ToastPrimitive.Description className={styles.toastDescription}>
          {content}
        </ToastPrimitive.Description>
      )}
      {closeButton && !children && (
        <ToastPrimitive.Close className={styles.toastClose}>
          <CloseIcon height={13} className={styles.closeIcon} />
        </ToastPrimitive.Close>
      )}
      {children && (
        <ToastPrimitive.Action
          asChild
          altText='Toast Action'
          className={styles.toastAction}
        >
          {children}
        </ToastPrimitive.Action>
      )}
    </ToastPrimitive.Root>
  )
}
