import { IconProps } from '.'

export function DiscountIcon({
  width = 18,
  height = 18,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox='0 0 18 18'
      focusable='false'
      width={width}
      height={height}
      color={color}
      aria-hidden
      role='img'
      {...props}
    >
      <path
        d='M9.59055 16.1268L2.24805 8.78433V10.2843C2.24805 10.6818 2.40555 11.0643 2.69055 11.3418L8.53305 17.1843C9.11805 17.7693 10.0705 17.7693 10.6555 17.1843L15.313 12.5268C15.898 11.9418 15.898 10.9893 15.313 10.4043L9.59055 16.1268Z'
        fill='currentColor'
      />
      <path
        d='M8.53305 13.4343C9.11805 14.0193 10.0705 14.0193 10.6555 13.4343L15.313 8.77683C15.898 8.19183 15.898 7.23933 15.313 6.65433L9.47055 0.811831C9.18555 0.534331 8.80305 0.376831 8.40555 0.376831H3.74805C2.92305 0.376831 2.24805 1.05183 2.24805 1.87683V6.53433C2.24805 6.93183 2.40555 7.31433 2.69055 7.59183L8.53305 13.4343ZM5.43555 2.62683C5.95305 2.62683 6.37305 3.04683 6.37305 3.56433C6.37305 4.08183 5.95305 4.50183 5.43555 4.50183C4.91805 4.50183 4.49805 4.08183 4.49805 3.56433C4.49805 3.04683 4.91805 2.62683 5.43555 2.62683Z'
        fill='currentColor'
      />
    </svg>
  )
}
