import styles from './Loader.module.scss'
import * as React from 'react'

interface LoadingProps {
  fullScreen?: boolean
  className?: string
}

export const Loader = ({
  fullScreen = false,
  className = '',
}: LoadingProps) => {
  return (
    <div
      className={fullScreen ? styles.fullScreen : styles.container}
      data-testid='spinnerContainer'
    >
      <div className={`${styles.spinner} ${className}`} />
    </div>
  )
}
