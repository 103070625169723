import React from 'react'
import { IconProps } from '.'
export function ChevronDownIcon({
  width = 18,
  height = 18,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox='0 0 24 24'
      focusable='false'
      width={width}
      height={height}
      fill={color}
      aria-hidden
      role='img'
      {...props}
    >
      <path
        fill='currentColor'
        d='M8.12498 8.99999L12.005 12.88L15.885 8.99999C16.275 8.60999 16.905 8.60999 17.295 8.99999C17.685 9.38999 17.685 10.02 17.295 10.41L12.705 15C12.315 15.39 11.685 15.39 11.295 15L6.70498 10.41C6.31498 10.02 6.31498 9.38999 6.70498 8.99999C7.09498 8.61999 7.73498 8.60999 8.12498 8.99999Z'
      ></path>
    </svg>
  )
}
