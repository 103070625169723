import React from 'react'
import { IconProps } from '.'
export function ChevronRightIcon({
  width = 18,
  height = 18,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox='0 0 24 24'
      focusable='false'
      width={width}
      height={height}
      fill={color}
      aria-hidden
      role='img'
      {...props}
    >
      <path
        fill='currentColor'
        d='M9.00002 15.875L12.88 11.995L9.00002 8.11501C8.61002 7.72501 8.61002 7.09501 9.00002 6.70501C9.39002 6.31501 10.02 6.31501 10.41 6.70501L15 11.295C15.39 11.685 15.39 12.315 15 12.705L10.41 17.295C10.02 17.685 9.39002 17.685 9.00002 17.295C8.62002 16.905 8.61002 16.265 9.00002 15.875Z'
      ></path>
    </svg>
  )
}
