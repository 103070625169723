'use client'
import { useRef, useState } from 'react'
import { CloseIcon } from '../../../theme/icons/CloseIcon'
import { Drawer } from '../../Drawer/Drawer'
import { User } from './../../../index'
import styles from './DoNotSellDrawer.module.scss'

export const DoNotSellDrawer = ({ user }: { user: User }) => {
  const { id, screen_name } = user
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen)
  const btnRef = useRef<HTMLButtonElement>(null)
  const manageMyCookies = () => {
    ;(window as any).OneTrust?.ToggleInfoDisplay()
  }
  const isRegisteredUser = Boolean(id && screen_name)
  const privacyPath = isRegisteredUser
    ? `/account/${id}?sub_action=privacy`
    : '/en/request-for-information'

  return (
    <>
      <button
        ref={btnRef}
        onClick={toggleDrawer}
        data-testid='button-doNotSell'
        className={styles.doNotSellLink}
      >
        Do Not Sell or Share My Personal Information
      </button>
      <Drawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer}>
        <div className={styles.topBar}>
          <button
            className={styles.closeButton}
            onClick={toggleDrawer}
            aria-label='close'
          >
            <CloseIcon width={12} height={12} />
          </button>
        </div>
        <div className={styles.privacyContent}>
          <div className={styles.heading}>Your Privacy</div>
          <div>
            When you visit our site, cookies are stored on your browser to
            provide you advertising more relevant to your preferences.
          </div>
          <button className={styles.cookiesButton} onClick={manageMyCookies}>
            Manage Cookies
          </button>
          <div>
            If you want to see more data options,&nbsp;
            <a
              href={privacyPath}
              aria-label='Click here to get more data options'
              title='Click here to get more data options'
            >
              click here
            </a>
            .
          </div>
        </div>
      </Drawer>
    </>
  )
}
