import React from 'react'
import { IconProps } from '.'

export function LeedIcon({
  width = 64,
  height = 64,
  color = '#5F6368',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 88 88'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M42.1071 65.2365V52.1623H35.1429C32.0391 52.1623 29.4061 51.0898 27.244 48.9448C25.0813 46.7994 24 44.1872 24 41.1082C24 38.6638 24.8018 36.5 26.4055 34.6167C28.0091 32.7338 29.9679 31.2981 32.2819 30.3097C32.664 26.678 33.8721 23.8852 35.9061 21.9314C37.9402 19.9771 40.4715 19 43.5 19C46.5285 19 49.0598 19.9771 51.0939 21.9314C53.1279 23.8852 54.336 26.678 54.7181 30.3097C57.0678 31.2272 59.0357 32.6451 60.6217 34.5635C62.2072 36.4823 63 38.6638 63 41.1082C63 44.1872 61.9187 46.7994 59.756 48.9448C57.5939 51.0898 54.9609 52.1623 51.8571 52.1623H44.8929V65.2365H58.8214C59.2161 65.2365 59.5469 65.3689 59.8138 65.6337C60.0808 65.899 60.2143 66.2277 60.2143 66.6196C60.2143 67.0116 60.0808 67.3395 59.8138 67.6034C59.5469 67.8678 59.2161 68 58.8214 68H28.1786C27.7839 68 27.4531 67.8674 27.1862 67.6021C26.9192 67.3368 26.7857 67.0081 26.7857 66.6162C26.7857 66.2242 26.9192 65.8963 27.1862 65.6324C27.4531 65.3684 27.7839 65.2365 28.1786 65.2365H42.1071ZM35.1429 49.3987H51.8571C54.1786 49.3987 56.1518 48.5927 57.7768 46.9807C59.4018 45.3686 60.2143 43.4111 60.2143 41.1082C60.2143 39.2023 59.5866 37.5319 58.3311 36.0972C57.0757 34.662 55.5213 33.5866 53.6679 32.8708L52.1357 32.2649L51.9324 30.4686C51.618 27.5812 50.7099 25.4086 49.2079 23.9508C47.7064 22.4926 45.8038 21.7635 43.5 21.7635C41.1962 21.7635 39.2936 22.4926 37.7921 23.9508C36.2901 25.4086 35.382 27.5812 35.0676 30.4686L34.8643 32.2649L32.9038 33.0836C31.1929 33.7993 29.7455 34.8569 28.5616 36.2561C27.3777 37.6558 26.7857 39.2732 26.7857 41.1082C26.7857 43.4111 27.5982 45.3686 29.2232 46.9807C30.8482 48.5927 32.8214 49.3987 35.1429 49.3987Z'
        fill={color}
      />
    </svg>
  )
}
