import React from 'react'
import { IconProps } from '.'

export function ArrowDownIcon({
  width = 24,
  height = 24,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      viewBox='0 0 24 24'
      focusable='false'
      width={width}
      height={height}
      fill={color}
      aria-hidden
      role='img'
      {...props}
    >
      <path
        fill='currentColor'
        d='M11.005 5.20898V16.379L6.12498 11.499C5.73498 11.109 5.09498 11.109 4.70498 11.499C4.31498 11.889 4.31498 12.519 4.70498 12.909L11.295 19.499C11.685 19.889 12.315 19.889 12.705 19.499L19.295 12.909C19.685 12.519 19.685 11.889 19.295 11.499C18.905 11.109 18.275 11.109 17.885 11.499L13.005 16.379V5.20898C13.005 4.65898 12.555 4.20898 12.005 4.20898C11.455 4.20898 11.005 4.65898 11.005 5.20898Z'
      />
    </svg>
  )
}
