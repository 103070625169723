import React from 'react'
import { IconProps } from '.'

export function ForestStewardshipCouncilIcon({
  width = 42,
  height = 91,
  color = 'currentColor',
  ...props
}: IconProps): JSX.Element {
  return (
    <svg
      width={width}
      height={height}
      viewBox='0 0 100 149'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <g clipPath='url(#clip0_495_27369)'>
        <path
          d='M94.5 0.5H5.5C2.73858 0.5 0.5 2.73858 0.5 5.5V112C0.5 114.761 2.73858 117 5.5 117H94.5C97.2614 117 99.5 114.761 99.5 112V5.5C99.5 2.73858 97.2614 0.5 94.5 0.5Z'
          fill='white'
        />
        <path
          d='M94.5 0.5H5.5C2.73858 0.5 0.5 2.73858 0.5 5.5V112C0.5 114.761 2.73858 117 5.5 117H94.5C97.2614 117 99.5 114.761 99.5 112V5.5C99.5 2.73858 97.2614 0.5 94.5 0.5Z'
          stroke='#536063'
          strokeWidth='2'
        />
        <path
          d='M27.3037 93.3698L25.752 88.5081H26.6445L27.4584 91.3088L27.7464 92.3553C27.7583 92.3073 27.8487 91.9752 28.0177 91.3615L28.8198 88.5081H29.6932L30.4453 91.3248L30.6905 92.2546L30.9832 91.3203L31.8709 88.5059H32.7039L31.0832 93.3675H30.2192L29.4291 90.4569L29.2315 89.6302L28.1915 93.3698H27.3037Z'
          fill='#536063'
        />
        <path
          d='M34.52 93.3698L32.9658 88.5081H33.8583L34.6723 91.3088L34.9603 92.3553C34.9722 92.3073 35.0626 91.9752 35.2316 91.3615L36.0313 88.5081H36.9047L37.6592 91.3248L37.9019 92.2546L38.1947 91.3203L39.0848 88.5059H39.9178L38.2946 93.3675H37.4331L36.6405 90.4569L36.443 89.6302L35.4006 93.3698H34.52Z'
          fill='#536063'
        />
        <path
          d='M41.7314 93.3698L40.1797 88.5081H41.0722L41.8861 91.3088L42.1741 92.3553C42.186 92.3073 42.2765 91.9752 42.4454 91.3615L43.2475 88.5081H44.1186L44.873 91.3248L45.1182 92.2546L45.4109 91.3203L46.2987 88.5059H47.1317L45.5109 93.3675H44.6469L43.8568 90.4569L43.6592 89.6302L42.6192 93.3698H41.7314Z'
          fill='#536063'
        />
        <path
          d='M47.3125 93.3694V92.4351H48.2812V93.3694H47.3125Z'
          fill='#536063'
        />
        <path
          d='M49.7421 93.3696V89.1468H49.002V88.5079H49.7421V87.9927C49.7421 87.6652 49.7755 87.427 49.8326 87.269C49.9159 87.056 50.0634 86.8843 50.2705 86.7515C50.4799 86.6164 50.7703 86.5522 51.144 86.5522C51.382 86.5522 51.6485 86.5797 51.9389 86.6324L51.8127 87.3469C51.6319 87.3171 51.4653 87.3011 51.3082 87.3011C51.0511 87.3011 50.8631 87.3515 50.7584 87.4591C50.6513 87.569 50.5989 87.7659 50.5989 88.0568V88.5033H51.5843V89.1422H50.5989V93.365H49.7421V93.3696Z'
          fill='#536063'
        />
        <path
          d='M52.0176 91.9018L52.8744 91.7942C52.922 92.1216 53.0481 92.3667 53.2647 92.5453C53.4837 92.7102 53.7835 92.7995 54.1691 92.7995C54.5594 92.7995 54.8498 92.7193 55.0354 92.5705C55.2258 92.4102 55.3186 92.2316 55.3186 92.0255C55.3186 91.8377 55.2377 91.6911 55.0687 91.5835C54.9497 91.5125 54.6641 91.4186 54.2024 91.3064C53.586 91.1553 53.1576 91.0202 52.9148 90.9125C52.6673 90.7957 52.4817 90.6446 52.3603 90.4522C52.2318 90.2507 52.1699 90.0355 52.1699 89.7996C52.1699 89.5797 52.2199 89.3782 52.327 89.1996C52.4269 89.0118 52.5674 88.8607 52.7411 88.7347C52.8791 88.6454 53.0529 88.5653 53.2837 88.4943C53.5146 88.4325 53.7574 88.4004 54.0215 88.4004C54.4119 88.4004 54.757 88.4531 55.0568 88.5653C55.3543 88.6775 55.5733 88.824 55.7161 89.0118C55.8565 89.1996 55.9541 89.4538 56.0088 89.7675L55.1711 89.8797C55.133 89.6255 55.0235 89.4286 54.8474 89.2843C54.6665 89.1446 54.4095 89.0736 54.0858 89.0736C53.7002 89.0736 53.4242 89.1355 53.2552 89.2614C53.0862 89.3874 53.0077 89.5294 53.0077 89.6988C53.0077 89.8065 53.041 89.9049 53.11 89.9897C53.179 90.0744 53.2861 90.15 53.4384 90.2095C53.5194 90.2416 53.7693 90.3125 54.1786 90.4202C54.776 90.5759 55.1973 90.7018 55.4329 90.8003C55.6709 90.9034 55.8589 91.0453 55.9946 91.2331C56.1278 91.4209 56.1969 91.6591 56.1969 91.9407C56.1969 92.2132 56.1136 92.4674 55.9493 92.7147C55.7851 92.9552 55.5447 93.1453 55.233 93.2735C54.9188 93.4086 54.5666 93.475 54.1715 93.475C53.5217 93.475 53.0219 93.3445 52.6768 93.0811C52.3341 92.8201 52.1128 92.4216 52.0176 91.9018Z'
          fill='#536063'
        />
        <path
          d='M60.4358 91.5925L61.2735 91.7047C61.1831 92.2589 60.9475 92.694 60.5762 93.0008C60.1954 93.3192 59.7337 93.4749 59.1887 93.4749C58.5008 93.4749 57.9487 93.255 57.5346 92.8268C57.1181 92.3894 56.9062 91.7665 56.9062 90.9582C56.9062 90.4315 56.9991 89.9689 57.1752 89.575C57.3537 89.1857 57.6274 88.8903 57.9963 88.6934C58.3652 88.4918 58.7626 88.3979 59.1958 88.3979C59.7408 88.3979 60.1835 88.5285 60.5334 88.801C60.8808 89.0689 61.1046 89.4536 61.1998 89.9506L60.362 90.0582C60.2858 89.7353 60.143 89.4857 59.9431 89.3208C59.7456 89.1559 59.5052 89.0758 59.2196 89.0758C58.7912 89.0758 58.4461 89.2223 58.1795 89.5178C57.9106 89.8177 57.7773 90.2918 57.7773 90.9353C57.7773 91.5833 57.9058 92.062 58.1653 92.3528C58.4223 92.6528 58.7603 92.7993 59.1768 92.7993C59.51 92.7993 59.7884 92.7009 60.0145 92.5039C60.2335 92.3093 60.3787 92.0047 60.4358 91.5925Z'
          fill='#536063'
        />
        <path
          d='M62.1875 93.3694V92.4351H63.1585V93.3694H62.1875Z'
          fill='#536063'
        />
        <path
          d='M63.9512 90.94C63.9512 90.0355 64.2082 89.3691 64.7294 88.9363C65.1626 88.579 65.691 88.4004 66.3145 88.4004C67.0095 88.4004 67.5759 88.6202 68.0186 89.053C68.4565 89.4904 68.6755 90.095 68.6755 90.869C68.6755 91.4942 68.5803 91.9865 68.3851 92.3461C68.19 92.7056 67.9043 92.9804 67.5331 93.1819C67.1665 93.3789 66.7548 93.4773 66.3145 93.4773C65.6077 93.4773 65.0388 93.2575 64.6033 92.8247C64.1701 92.385 63.9512 91.7598 63.9512 90.94ZM64.827 90.9354C64.827 91.556 64.9698 92.0277 65.253 92.3346C65.5339 92.6438 65.8909 92.7995 66.3169 92.7995C66.7429 92.7995 67.0999 92.6438 67.3808 92.33C67.664 92.0209 67.8068 91.5469 67.8068 90.9079C67.8068 90.3034 67.664 89.8477 67.3784 89.5385C67.0952 89.2294 66.7382 89.0736 66.3169 89.0736C65.8885 89.0736 65.5339 89.2294 65.253 89.5339C64.9674 89.8454 64.827 90.3148 64.827 90.9354Z'
          fill='#536063'
        />
        <path
          d='M69.6631 93.3697V88.508H70.4175V89.2546C70.6175 88.9065 70.7983 88.6775 70.9626 88.5653C71.1315 88.4531 71.3124 88.4004 71.51 88.4004C71.8003 88.4004 72.0907 88.4851 72.3929 88.6683L72.0883 89.4194C71.8836 89.3118 71.6789 89.2591 71.4695 89.2591C71.2862 89.2591 71.122 89.3118 70.9697 89.4194C70.8245 89.5271 70.7174 89.6782 70.6532 89.8706C70.5604 90.1614 70.5127 90.4797 70.5127 90.8278V93.3674H69.6631V93.3697Z'
          fill='#536063'
        />
        <path
          d='M72.6596 93.7682L73.514 93.8804C73.5497 94.1346 73.6425 94.3132 73.7996 94.4345C74.0114 94.5857 74.3018 94.6681 74.6659 94.6681C75.0562 94.6681 75.3633 94.588 75.5775 94.4345C75.7917 94.2811 75.9368 94.0636 76.0154 93.7911C76.0606 93.6216 76.0773 93.2666 76.0749 92.7262C75.7012 93.1567 75.2324 93.3697 74.6754 93.3697C73.9757 93.3697 73.4378 93.1292 73.057 92.6461C72.6762 92.1629 72.4858 91.5858 72.4858 90.9102C72.4858 90.4499 72.5739 90.0194 72.7453 89.6255C72.9238 89.2362 73.1737 88.9317 73.5069 88.7187C73.8377 88.5034 74.228 88.4004 74.6731 88.4004C75.2704 88.4004 75.7607 88.6386 76.1463 89.1126V88.508H76.9245V92.7079C76.9245 93.4636 76.846 93.9994 76.6842 94.3177C76.5295 94.6315 76.2724 94.8811 75.9273 95.0643C75.5798 95.2475 75.1538 95.3368 74.6493 95.3368C74.0495 95.3368 73.5616 95.2109 73.1903 94.9521C72.8238 94.6887 72.6453 94.2949 72.6596 93.7682ZM73.3593 90.8507C73.3593 91.4896 73.4973 91.9545 73.7591 92.2499C74.0209 92.5453 74.3565 92.6873 74.754 92.6873C75.1514 92.6873 75.4823 92.5453 75.7512 92.2545C76.0178 91.959 76.151 91.5033 76.151 90.8759C76.151 90.2805 76.0154 89.8294 75.7369 89.5248C75.4632 89.2248 75.13 89.0736 74.7397 89.0736C74.3565 89.0736 74.0328 89.2202 73.7639 89.5202C73.495 89.8225 73.3593 90.2644 73.3593 90.8507Z'
          fill='#536063'
        />
        <path
          d='M51.7915 8.64404C36.3762 8.64404 30.9308 31.7021 25.859 50.2465C24.0288 48.4626 18.3882 43.0513 18.381 43.0582C17.2458 41.9773 15.9439 42.3254 15.1442 43.0788C14.3446 43.8528 14.2279 45.284 15.1442 46.152C15.1895 46.1886 22.7888 53.4822 25.4187 56.0058C26.1422 56.686 27.1894 57.0844 28.2319 56.4936C29.3219 55.857 29.4718 54.7143 29.498 54.7143C38.4992 15.8965 46.546 13.0042 51.7677 13.0042C56.1612 13.0042 61.2234 16.1278 61.2234 22.3772C67.7779 22.3772 71.8834 30.6532 66.607 35.7553C70.9814 37.7889 72.9283 40.2941 72.9283 43.4589C72.9283 46.6306 70.1675 49.8663 66.1453 49.8572C62.154 49.8572 60.1239 47.3908 57.0108 47.384C55.6019 47.384 54.7427 48.4786 54.7427 49.5572V57.3959H48.8688V49.5892C48.8593 48.3641 47.9192 47.4023 46.7554 47.4023C46.734 47.4023 46.2508 47.4137 46.2627 47.4275L38.3968 48.3687C37.4448 48.4992 36.5714 49.4885 36.5714 50.519C36.5714 51.7212 37.59 52.7128 38.9871 52.6991C39.1608 52.6991 39.3988 52.6991 39.3774 52.6785C39.3655 52.6785 44.3587 52.0647 44.3587 52.0647C44.3587 52.0647 44.3587 59.7706 44.3587 59.8003C44.3587 60.785 45.5107 61.7377 46.546 61.74H56.9965C58.2151 61.74 59.2671 60.7324 59.2671 59.5439V52.4815C61.4543 53.7685 64.4935 54.2654 66.0572 54.2654C71.9168 54.2654 77.5145 49.8297 77.4883 43.3307C77.4645 37.4271 72.7807 34.4867 72.7807 34.4867C75.532 27.4335 71.0861 20.6276 65.317 18.7338C63.2345 11.4585 56.78 8.64404 51.7915 8.64404Z'
          fill='#536063'
        />
        <path
          d='M45.2083 70.3687C45.2083 72.1939 47.3265 73.2908 50.8061 74.8846C53.5692 76.1304 55.1591 77.4471 55.1591 78.5005C55.1591 80.2134 53.2432 80.8203 51.451 80.8203C48.676 80.8203 47.0362 79.7577 46.1318 77.3716L46.0556 77.1655H45.8319C45.3392 77.1655 44.9513 77.1861 44.7347 77.4494C44.5443 77.6876 44.5966 78.0059 44.6823 78.3173C44.6752 78.3104 45.2916 80.7676 45.2916 80.7676C45.4535 81.3676 45.6772 81.4638 46.6673 81.6493C47.1004 81.7226 49.3424 82.0981 51.2416 82.0981C56.5419 82.0981 58.9123 80.0257 58.9123 77.9715C58.9123 76.4762 57.3725 75.0426 54.05 73.4511L52.5363 72.7503C50.2634 71.7084 48.8949 71.0855 48.8949 69.9886C48.8949 68.926 50.3134 68.1818 52.3531 68.1818C55.002 68.1818 55.7136 69.6084 56.0373 70.8519L56.0968 71.0878H56.3515C56.9393 71.0878 57.313 71.042 57.5296 70.8084C57.6605 70.6802 57.7057 70.5015 57.6819 70.2886L57.4701 68.1657C57.413 67.4879 56.8727 67.3872 56.1254 67.2452C55.2352 67.0895 54.169 66.9062 52.5577 66.9062C47.9572 66.9062 45.2083 68.2024 45.2083 70.3687Z'
          fill='#536063'
        />
        <path
          d='M60.4712 74.6646C60.4712 78.3538 63.8008 82.0957 70.1673 82.0957C73.3803 82.0957 75.1891 81.3903 76.5362 80.7011L76.9027 80.511L76.3077 79.8835L76.341 79.9156C76.203 79.7209 76.0864 79.6889 75.8745 79.6889L75.5628 79.7896C75.0796 80.0072 73.6064 80.6644 70.9313 80.6644C67.6397 80.6644 64.0912 78.5965 64.0912 74.044C64.0912 69.7136 67.9373 68.1793 71.2193 68.1793C73.585 68.1793 74.8416 68.9923 75.0701 70.6709L75.1034 70.9479H75.3986C75.677 70.9479 76.4267 70.9479 76.4267 70.4098V67.8816C76.4267 67.2656 75.6865 67.1717 75.3724 67.1305L75.0654 67.0916C74.3085 66.9908 73.5826 66.9038 71.662 66.9038C65.1788 66.9061 60.4712 70.1671 60.4712 74.6646Z'
          fill='#536063'
        />
        <path
          d='M36.4645 67.0848L30.7311 67.1329L26.9041 67.0688L26.3233 67.055C25.983 67.055 25.7402 67.2932 25.7402 67.6321C25.7402 67.7901 25.7402 68.2825 26.3709 68.2825C28.6819 68.2825 28.7652 68.468 28.7914 69.3175L28.8081 69.8557L28.8438 72.1274V77.0234C28.8438 77.8363 28.8152 79.1256 28.7605 80.1676C28.7462 80.4653 28.7271 80.7492 26.6851 80.7492C26.1163 80.7492 25.7712 80.8591 25.7712 81.4019C25.7712 81.5622 25.7712 81.9812 26.3233 81.9812L26.4542 81.9767C26.6946 81.9652 27.3444 81.9377 30.5359 81.9377C33.9346 81.9377 34.6224 81.9698 34.8485 81.9767L34.9746 81.9812C35.4197 81.9812 35.8076 81.9332 35.8076 81.4019C35.8076 80.795 35.2864 80.7492 34.4249 80.7492C32.3733 80.7492 32.3614 80.4653 32.3471 80.1721C32.2971 79.2149 32.2567 77.9234 32.2567 77.0234C32.2567 77.0234 32.2567 75.7158 32.2567 75.2624C32.7184 75.2624 33.9703 75.2624 33.9703 75.2624C36.6026 75.2624 36.9001 75.3288 37.2761 77.2822C37.3784 77.7058 37.7307 77.7585 38.0258 77.7585C38.6446 77.7585 38.7136 77.4333 38.7136 77.2867L38.6636 76.5608C38.6208 76.1417 38.5756 75.6173 38.5756 74.9074V71.9098C38.5756 71.4564 38.0163 71.4564 37.714 71.4564C37.2166 71.4564 37.1 71.7633 36.7834 73.1808C36.643 73.7212 35.5577 74.0327 33.8061 74.0327C33.8061 74.0327 32.7065 74.0327 32.2567 74.0327C32.2567 73.4808 32.2567 69.6267 32.2567 69.6267C32.2567 68.4267 32.478 68.4199 32.7517 68.4084C33.7561 68.3672 36.198 68.2825 36.9286 68.2825C37.6236 68.2825 38.2638 68.4359 38.6779 68.6992C39.9774 69.4778 40.6462 70.0068 41.0508 70.3229C41.4435 70.6297 41.6648 70.8083 41.9909 70.8083C42.1741 70.8083 42.7311 70.8083 42.7311 70.348C42.7311 70.2358 42.6835 70.1305 42.5026 69.7504C42.2908 69.2992 41.929 68.5527 41.4792 67.4123C41.353 67.0527 40.9175 67.0527 40.1131 67.0527L36.4645 67.0848Z'
          fill='#536063'
        />
        <path
          d='M70.6083 10.8695C71.4552 10.8695 72.183 11.1724 72.7915 11.7782C73.4 12.3839 73.7042 13.1144 73.7042 13.9696C73.7042 14.822 73.4013 15.5525 72.7956 16.161C72.1898 16.7695 71.4607 17.0737 70.6083 17.0737C69.7531 17.0737 69.0212 16.7709 68.4127 16.1651C67.8042 15.5593 67.5 14.8275 67.5 13.9696C67.5 13.1171 67.8042 12.388 68.4127 11.7823C69.0212 11.1765 69.7531 10.8723 70.6083 10.8695ZM70.6 16.5639C71.31 16.5639 71.9185 16.3104 72.4255 15.8033C72.9326 15.2962 73.1875 14.685 73.1903 13.9696C73.1903 13.2569 72.9367 12.6484 72.4296 12.1441C71.9226 11.6397 71.3127 11.3862 70.6 11.3835C69.8874 11.3835 69.2789 11.6356 68.7746 12.14C68.2702 12.6443 68.0167 13.2542 68.0139 13.9696C68.0139 14.6822 68.2661 15.2921 68.7704 15.7992C69.2748 16.3063 69.8847 16.5612 70.6 16.5639ZM72.0473 15.6882H71.3894C70.9756 14.9344 70.7056 14.5095 70.5795 14.4136C70.4534 14.3177 70.2944 14.2697 70.1026 14.2697H69.7819V15.6882H69.2433V12.3538H70.3986C70.7439 12.3538 71.0098 12.3784 71.1962 12.4278C71.3826 12.4771 71.5333 12.5813 71.6485 12.7402C71.7636 12.8992 71.8212 13.0705 71.8212 13.2542C71.8212 13.4817 71.7389 13.6886 71.5745 13.875C71.41 14.0614 71.1743 14.1656 70.8673 14.1875V14.2039C71.0674 14.2505 71.3264 14.5287 71.6444 15.0386L72.0473 15.6882ZM69.7819 13.8092H70.4397C70.7412 13.8092 70.9509 13.7654 71.0688 13.6777C71.1866 13.5899 71.2455 13.4652 71.2455 13.3035C71.2455 13.1473 71.1894 13.0253 71.077 12.9376C70.9646 12.8499 70.7385 12.806 70.3986 12.806H69.7819V13.8092Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M24.7406 104.188H21.4719V106.055H24.3047V106.77H21.4719V109.5H20.6743V103.473H24.7406V104.188Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M29.6989 105.24C29.666 104.857 29.5276 104.569 29.2837 104.377C29.0397 104.185 28.6902 104.088 28.2352 104.085C27.8241 104.085 27.4897 104.162 27.232 104.315C26.9744 104.469 26.8456 104.692 26.8456 104.985C26.8456 105.246 26.9442 105.439 27.1416 105.565C27.3389 105.691 27.7199 105.819 28.2846 105.947C28.7834 106.057 29.1905 106.169 29.5057 106.285C29.8209 106.4 30.0909 106.578 30.3156 106.819C30.5404 107.06 30.6528 107.386 30.6528 107.798C30.6528 108.316 30.4472 108.746 30.0361 109.089C29.6249 109.431 29.0863 109.603 28.4203 109.603C27.5733 109.603 26.9401 109.413 26.5208 109.035C26.1014 108.657 25.878 108.166 25.8506 107.563L26.6071 107.497C26.6427 107.98 26.8291 108.331 27.1663 108.55C27.5034 108.769 27.9104 108.88 28.3874 108.883C28.8341 108.883 29.1946 108.791 29.4687 108.608C29.7428 108.424 29.8812 108.174 29.8839 107.859C29.8839 107.53 29.74 107.291 29.4522 107.14C29.1644 106.989 28.682 106.841 28.005 106.696C27.328 106.55 26.8387 106.344 26.5372 106.075C26.2357 105.806 26.0863 105.461 26.0891 105.039C26.0891 104.556 26.2782 104.158 26.6564 103.842C27.0347 103.527 27.55 103.37 28.2023 103.37C28.8711 103.37 29.4084 103.526 29.814 103.838C30.2197 104.151 30.4362 104.598 30.4637 105.179L29.6989 105.24Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M37.1696 107.588C36.9941 108.265 36.6885 108.769 36.2527 109.101C35.8169 109.433 35.292 109.6 34.678 109.603C33.6913 109.603 32.9731 109.294 32.5236 108.677C32.0741 108.061 31.848 107.317 31.8452 106.445C31.8452 105.483 32.1097 104.73 32.6387 104.188C33.1677 103.645 33.8502 103.372 34.6862 103.37C35.2728 103.37 35.7744 103.519 36.191 103.818C36.6077 104.116 36.8996 104.554 37.0668 105.129L36.2856 105.314C36.154 104.895 35.9539 104.58 35.6853 104.369C35.4167 104.158 35.0809 104.052 34.678 104.052C34.0202 104.052 33.5213 104.267 33.1814 104.698C32.8416 105.128 32.6716 105.708 32.6716 106.437C32.6716 107.275 32.8498 107.899 33.2061 108.307C33.5624 108.716 34.0298 108.92 34.6081 108.92C35.0796 108.92 35.4619 108.793 35.7552 108.538C36.0485 108.283 36.2554 107.899 36.3761 107.386L37.1696 107.588Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M40.0375 103.435C40.461 103.435 40.8249 103.586 41.1291 103.889C41.4334 104.192 41.5855 104.557 41.5855 104.985C41.5855 105.411 41.4341 105.776 41.1312 106.08C40.8283 106.384 40.4638 106.537 40.0375 106.537C39.6099 106.537 39.244 106.385 38.9398 106.082C38.6355 105.779 38.4834 105.414 38.4834 104.985C38.4834 104.558 38.6355 104.194 38.9398 103.891C39.244 103.588 39.6099 103.436 40.0375 103.435ZM40.0334 106.282C40.3884 106.282 40.6926 106.155 40.9462 105.901C41.1997 105.648 41.3272 105.342 41.3285 104.985C41.3285 104.628 41.2018 104.324 40.9482 104.072C40.6947 103.82 40.3897 103.693 40.0334 103.691C39.6771 103.691 39.3728 103.818 39.1207 104.07C38.8685 104.322 38.7417 104.627 38.7404 104.985C38.7404 105.341 38.8664 105.646 39.1186 105.899C39.3708 106.153 39.6757 106.28 40.0334 106.282ZM40.757 105.844H40.4281C40.2212 105.467 40.0862 105.255 40.0231 105.207C39.9601 105.159 39.8806 105.135 39.7847 105.135H39.6243V105.844H39.355V104.177H39.9327C40.1054 104.177 40.2383 104.189 40.3315 104.214C40.4247 104.238 40.5001 104.29 40.5576 104.37C40.6152 104.449 40.644 104.535 40.644 104.627C40.644 104.741 40.6029 104.844 40.5206 104.937C40.4384 105.03 40.3205 105.083 40.167 105.093V105.102C40.2671 105.125 40.3966 105.264 40.5556 105.519L40.757 105.844ZM39.6243 104.904H39.9532C40.104 104.904 40.2088 104.882 40.2678 104.839C40.3267 104.795 40.3562 104.732 40.3562 104.652C40.3562 104.573 40.3281 104.512 40.2719 104.469C40.2157 104.425 40.1026 104.403 39.9327 104.403H39.6243V104.904Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.328917'
        />
        <path
          d='M51.1036 107.588C50.9282 108.265 50.6226 108.769 50.1868 109.101C49.751 109.433 49.2261 109.6 48.6121 109.603C47.6253 109.603 46.9072 109.294 46.4577 108.677C46.0082 108.061 45.782 107.317 45.7793 106.445C45.7793 105.483 46.0438 104.73 46.5728 104.188C47.1018 103.645 47.7843 103.372 48.6203 103.37C49.2069 103.37 49.7085 103.519 50.1251 103.818C50.5417 104.116 50.8337 104.554 51.0009 105.129L50.2197 105.314C50.0881 104.895 49.888 104.58 49.6194 104.369C49.3508 104.158 49.015 104.052 48.6121 104.052C47.9543 104.052 47.4554 104.267 47.1155 104.698C46.7756 105.128 46.6057 105.708 46.6057 106.437C46.6057 107.275 46.7839 107.899 47.1402 108.307C47.4965 108.716 47.9639 108.92 48.5422 108.92C49.0137 108.92 49.396 108.793 49.6893 108.538C49.9826 108.283 50.1895 107.899 50.3101 107.386L51.1036 107.588Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M54.9191 109.5H54.1791V104.784C53.809 105.135 53.3143 105.432 52.6948 105.676V104.961C53.5473 104.553 54.1284 104.047 54.4381 103.444H54.9191V109.5Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M61.0658 108.061H60.2517V109.5H59.5117V108.061H56.9009V107.378L59.6432 103.473H60.2517V107.378H61.0658V108.061ZM59.5117 107.378V104.657H59.4952L57.6245 107.378H59.5117Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M66.0898 104.126C65.4977 104.776 64.9852 105.579 64.5521 106.536C64.119 107.492 63.8641 108.48 63.7873 109.5H63.0308C63.05 108.68 63.2515 107.781 63.6352 106.803C64.019 105.824 64.5192 104.98 65.1359 104.27H62.188V103.555H66.0898V104.126Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M71.0484 109.5H67.0562C67.0562 109.16 67.174 108.816 67.4097 108.468C67.6455 108.12 68.0895 107.684 68.7419 107.161C69.1393 106.843 69.4943 106.51 69.8067 106.161C70.1192 105.813 70.2754 105.461 70.2754 105.105C70.2754 104.801 70.1685 104.55 69.9547 104.352C69.7409 104.155 69.4682 104.055 69.1366 104.052C68.8049 104.052 68.5267 104.154 68.3019 104.357C68.0772 104.559 67.9634 104.865 67.9607 105.273L67.2001 105.187C67.2466 104.622 67.4467 104.191 67.8003 103.892C68.1539 103.593 68.6048 103.444 69.153 103.444C69.7451 103.444 70.2055 103.607 70.5345 103.933C70.8634 104.259 71.0292 104.655 71.0319 105.121C71.0319 105.508 70.8935 105.898 70.6167 106.293C70.3398 106.688 69.7848 107.218 68.9515 107.884C68.5157 108.232 68.2265 108.532 68.084 108.785H71.0484V109.5Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M74.1482 103.444C75.4584 103.444 76.1135 104.471 76.1135 106.527C76.1135 107.566 75.9449 108.338 75.6078 108.842C75.2706 109.346 74.7841 109.6 74.1482 109.603C72.8407 109.603 72.187 108.577 72.187 106.527C72.187 105.488 72.3556 104.715 72.6927 104.208C73.0299 103.701 73.515 103.446 74.1482 103.444ZM74.1482 108.994C74.5456 108.994 74.8458 108.802 75.0486 108.418C75.2514 108.035 75.3528 107.404 75.3528 106.527C75.3528 105.919 75.309 105.44 75.2213 105.092C75.1336 104.744 74.9993 104.484 74.8184 104.311C74.6374 104.138 74.4113 104.052 74.14 104.052C73.7425 104.052 73.4437 104.248 73.2437 104.64C73.0436 105.032 72.9435 105.661 72.9435 106.527C72.9435 107.127 72.9887 107.603 73.0792 107.954C73.1697 108.305 73.304 108.565 73.4821 108.735C73.6603 108.905 73.8823 108.991 74.1482 108.994Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M80.0114 106.239C80.3842 106.311 80.6651 106.49 80.8542 106.778C81.0434 107.066 81.1393 107.381 81.142 107.724C81.142 108.272 80.9433 108.721 80.5459 109.072C80.1484 109.423 79.6701 109.6 79.111 109.603C78.5792 109.603 78.1407 109.448 77.7953 109.138C77.4499 108.828 77.2485 108.418 77.1909 107.909L77.931 107.806C78.005 108.203 78.1393 108.501 78.3339 108.698C78.5285 108.895 78.7875 108.994 79.111 108.994C79.4563 108.994 79.7482 108.873 79.9867 108.632C80.2252 108.391 80.3458 108.094 80.3485 107.74C80.3485 107.403 80.2403 107.123 80.0237 106.901C79.8072 106.679 79.5221 106.568 79.1685 106.568C79.026 106.568 78.852 106.594 78.6464 106.646L78.7327 105.997C78.9301 105.997 79.0863 105.987 79.2014 105.968C79.3165 105.949 79.4454 105.902 79.5879 105.828C79.7304 105.754 79.8483 105.649 79.9415 105.512C80.0347 105.375 80.0813 105.202 80.0813 104.994C80.0813 104.695 79.9826 104.463 79.7853 104.299C79.5879 104.134 79.3577 104.052 79.0945 104.052C78.7958 104.052 78.5532 104.147 78.3668 104.336C78.1804 104.525 78.0612 104.795 78.0091 105.146L77.269 105.01C77.365 104.487 77.576 104.095 77.9022 103.834C78.2284 103.574 78.6203 103.444 79.0781 103.444C79.5797 103.444 79.9977 103.594 80.3321 103.896C80.6665 104.197 80.835 104.569 80.8378 105.01C80.8378 105.572 80.5623 105.976 80.0114 106.223V106.239Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M30.853 128.688H28.8589V134H28.0572V128.688H26.0796V127.973H30.853V128.688Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M35.4948 134H34.7506V131.245C34.7506 130.889 34.6739 130.622 34.5204 130.444C34.3669 130.265 34.138 130.176 33.8338 130.176C33.5323 130.176 33.2637 130.28 33.0279 130.489C32.7922 130.697 32.673 131.073 32.6702 131.615V134H31.9302V127.973H32.6702V130.135C33.0211 129.738 33.4596 129.539 33.9859 129.539C34.2682 129.539 34.5382 129.597 34.7959 129.712C35.0535 129.827 35.2344 130.004 35.3386 130.242C35.4427 130.481 35.4948 130.812 35.4948 131.237V134Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M40.7326 132.006H37.464C37.4942 132.488 37.6312 132.857 37.8752 133.112C38.1191 133.367 38.4261 133.494 38.7961 133.494C39.0785 133.494 39.3156 133.42 39.5074 133.272C39.6993 133.124 39.8459 132.898 39.9474 132.594L40.708 132.688C40.5846 133.143 40.3585 133.493 40.0296 133.737C39.7007 133.981 39.2895 134.103 38.7961 134.103C38.1411 134.103 37.6299 133.902 37.2626 133.502C36.8953 133.102 36.7103 132.553 36.7075 131.854C36.7075 131.166 36.8857 130.608 37.242 130.18C37.5983 129.753 38.1027 129.539 38.755 129.539C39.073 129.539 39.3786 129.609 39.6719 129.748C39.9652 129.888 40.216 130.125 40.4243 130.46C40.6326 130.794 40.7354 131.309 40.7326 132.006ZM39.972 131.397C39.9419 130.953 39.8048 130.634 39.5609 130.439C39.3169 130.245 39.0483 130.146 38.755 130.143C38.4069 130.143 38.1205 130.26 37.8957 130.493C37.671 130.726 37.5408 131.027 37.5051 131.397H39.972Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M50.5347 134H49.7946V131.245C49.7946 131.042 49.7809 130.87 49.7535 130.727C49.7261 130.585 49.6562 130.457 49.5438 130.345C49.4314 130.233 49.2533 130.176 49.0093 130.176C48.7188 130.176 48.468 130.278 48.2569 130.481C48.0459 130.683 47.9404 131.012 47.9404 131.467V134H47.2003V131.163C47.2003 130.785 47.1345 130.526 47.0029 130.386C46.8714 130.246 46.6822 130.176 46.4356 130.176C46.1176 130.176 45.8572 130.286 45.6544 130.505C45.4515 130.725 45.3488 131.134 45.346 131.735V134H44.606V129.638H45.272V130.246H45.2885C45.5845 129.775 46.0189 129.539 46.5918 129.539C46.9125 129.539 47.1756 129.602 47.3812 129.728C47.5868 129.854 47.7375 130.046 47.8335 130.304C48.1569 129.794 48.605 129.539 49.1779 129.539C49.6302 129.539 49.9687 129.661 50.1934 129.905C50.4182 130.149 50.532 130.515 50.5347 131.003V134Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M55.7478 134H54.9707C54.8994 133.863 54.8487 133.683 54.8186 133.461C54.3225 133.889 53.7866 134.103 53.211 134.103C52.7505 134.103 52.3873 133.987 52.1215 133.757C51.8556 133.527 51.7227 133.223 51.7227 132.844C51.7227 132.483 51.8501 132.185 52.105 131.952C52.3599 131.719 52.8053 131.563 53.4412 131.484L54.132 131.381C54.3896 131.34 54.6021 131.289 54.7693 131.229C54.7693 131.007 54.7624 130.85 54.7487 130.76C54.735 130.669 54.6952 130.574 54.6295 130.472C54.5637 130.371 54.4554 130.291 54.3047 130.234C54.1539 130.176 53.9552 130.146 53.7085 130.143C53.3905 130.143 53.1397 130.202 52.9561 130.32C52.7725 130.438 52.645 130.657 52.5737 130.978L51.8501 130.883C51.9296 130.436 52.1338 130.101 52.4627 129.876C52.7916 129.651 53.2439 129.539 53.8195 129.539C54.343 129.539 54.7268 129.616 54.9707 129.769C55.2147 129.923 55.3654 130.11 55.423 130.332C55.4805 130.554 55.5093 130.837 55.5093 131.179V132.174C55.5093 132.78 55.5203 133.186 55.5422 133.391C55.5641 133.597 55.6327 133.8 55.7478 134ZM54.7693 132.08V131.812C54.3828 131.941 53.91 132.043 53.3508 132.117C52.7916 132.191 52.5121 132.428 52.5121 132.828C52.5121 133.028 52.5874 133.193 52.7382 133.321C52.8889 133.45 53.1055 133.516 53.3878 133.519C53.7524 133.519 54.073 133.41 54.3499 133.194C54.6267 132.977 54.7665 132.606 54.7693 132.08Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M59.3291 129.773L59.0742 130.456C58.8905 130.349 58.711 130.296 58.5356 130.296C58.2587 130.296 58.049 130.419 57.9065 130.666C57.764 130.912 57.6927 131.26 57.6927 131.71V134H56.9526V129.638H57.6269V130.296H57.6434C57.8791 129.791 58.1874 129.539 58.5684 129.539C58.8206 129.539 59.0742 129.617 59.3291 129.773Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M63.7243 134H62.8156L61.3643 131.767L60.8421 132.265V134H60.1021V127.973H60.8421V131.406L62.5936 129.638H63.5557L61.8864 131.254L63.7243 134Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M69.0937 129.539C69.683 129.539 70.1709 129.731 70.5574 130.114C70.9439 130.498 71.1385 131.044 71.1412 131.751C71.1412 132.609 70.9315 133.214 70.5121 133.568C70.0928 133.922 69.62 134.1 69.0937 134.103C68.5428 134.103 68.0645 133.92 67.6588 133.556C67.2531 133.191 67.0503 132.613 67.0503 131.821C67.0503 131.053 67.2463 130.482 67.6382 130.106C68.0302 129.731 68.5153 129.542 69.0937 129.539ZM69.0937 133.494C69.5158 133.494 69.8365 133.338 70.0558 133.025C70.2751 132.713 70.3847 132.302 70.3847 131.792C70.3847 131.246 70.2613 130.835 70.0147 130.558C69.768 130.282 69.461 130.143 69.0937 130.143C68.7182 130.143 68.4098 130.283 68.1686 130.563C67.9274 130.842 67.8068 131.262 67.8068 131.821C67.8068 132.374 67.9288 132.791 68.1727 133.071C68.4167 133.35 68.7237 133.491 69.0937 133.494Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M74.3853 127.944L74.2742 128.593C74.1262 128.566 73.981 128.552 73.8384 128.552C73.6603 128.552 73.515 128.589 73.4026 128.663C73.2902 128.737 73.2327 128.925 73.2299 129.227V129.638H74.0728V130.209H73.2299V134H72.4899V130.209H71.832V129.638H72.4899V129.165C72.4899 128.946 72.5104 128.747 72.5515 128.569C72.5927 128.391 72.7009 128.23 72.8763 128.088C73.0518 127.945 73.3245 127.873 73.6945 127.87C73.9138 127.87 74.144 127.894 74.3853 127.944Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M13.0522 139.273L12.7973 139.956C12.6137 139.849 12.4341 139.796 12.2587 139.796C11.9819 139.796 11.7722 139.919 11.6296 140.166C11.4871 140.412 11.4158 140.76 11.4158 141.21V143.5H10.6758V139.138H11.3501V139.796H11.3665C11.6022 139.291 11.9106 139.039 12.2916 139.039C12.5438 139.039 12.7973 139.117 13.0522 139.273Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M17.6242 141.506H14.3556C14.3858 141.988 14.5228 142.357 14.7668 142.612C15.0107 142.867 15.3177 142.994 15.6877 142.994C15.9701 142.994 16.2072 142.92 16.399 142.772C16.5909 142.624 16.7375 142.398 16.839 142.094L17.5996 142.188C17.4762 142.643 17.2501 142.993 16.9212 143.237C16.5923 143.481 16.1811 143.603 15.6877 143.603C15.0327 143.603 14.5215 143.402 14.1542 143.002C13.7869 142.602 13.6019 142.053 13.5991 141.354C13.5991 140.666 13.7773 140.108 14.1336 139.68C14.4899 139.253 14.9943 139.039 15.6466 139.039C15.9646 139.039 16.2702 139.109 16.5635 139.248C16.8568 139.388 17.1076 139.625 17.3159 139.96C17.5242 140.294 17.627 140.809 17.6242 141.506ZM16.8636 140.897C16.8335 140.453 16.6964 140.134 16.4525 139.939C16.2085 139.745 15.9399 139.646 15.6466 139.643C15.2985 139.643 15.0121 139.76 14.7873 139.993C14.5626 140.226 14.4324 140.527 14.3967 140.897H16.8636Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M21.2793 140.359C21.2464 140.12 21.1504 139.942 20.9914 139.824C20.8325 139.706 20.6091 139.646 20.3213 139.643C20.039 139.643 19.8115 139.691 19.6388 139.787C19.4661 139.883 19.3784 140.023 19.3756 140.206C19.3756 140.379 19.4442 140.502 19.5812 140.576C19.7183 140.651 19.9924 140.741 20.4035 140.848C20.864 140.966 21.208 141.07 21.4355 141.16C21.663 141.251 21.8412 141.377 21.97 141.539C22.0988 141.7 22.1632 141.928 22.1632 142.221C22.1632 142.61 22.0042 142.938 21.6863 143.204C21.3683 143.47 20.9435 143.603 20.4117 143.603C19.8581 143.603 19.4264 143.486 19.1166 143.253C18.8069 143.02 18.615 142.668 18.541 142.196L19.277 142.085C19.3153 142.39 19.4277 142.617 19.6141 142.768C19.8005 142.919 20.0636 142.994 20.4035 142.994C20.7269 142.994 20.975 142.927 21.1477 142.793C21.3204 142.658 21.4067 142.492 21.4067 142.295C21.4067 142.164 21.367 142.058 21.2875 141.979C21.208 141.899 21.1093 141.84 20.9914 141.802C20.8736 141.763 20.605 141.692 20.1856 141.588C19.5661 141.44 19.1577 141.26 18.9604 141.049C18.763 140.838 18.663 140.586 18.6602 140.293C18.6602 139.928 18.8055 139.628 19.0961 139.392C19.3866 139.157 19.7772 139.039 20.2678 139.039C20.7886 139.039 21.1929 139.14 21.4807 139.343C21.7685 139.546 21.9426 139.852 22.0029 140.26L21.2793 140.359Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M24.0833 139.701C24.3601 139.259 24.7754 139.039 25.3291 139.039C25.9019 139.039 26.3542 139.246 26.6859 139.66C27.0175 140.074 27.1834 140.615 27.1834 141.284C27.1834 141.994 26.9997 142.557 26.6324 142.974C26.2651 143.39 25.8142 143.6 25.2797 143.603C24.811 143.603 24.4355 143.416 24.1532 143.043H24.1326V145.177H23.3926V139.138H24.0669V139.701H24.0833ZM25.2304 142.994C25.5758 142.994 25.8608 142.852 26.0856 142.566C26.3103 142.281 26.4241 141.854 26.4268 141.284C26.4268 140.749 26.3186 140.338 26.102 140.05C25.8855 139.762 25.6059 139.619 25.2633 139.619C24.9125 139.619 24.6246 139.779 24.3999 140.1C24.1751 140.42 24.0614 140.836 24.0586 141.345C24.0586 141.885 24.1669 142.295 24.3834 142.575C24.6 142.854 24.8823 142.994 25.2304 142.994Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M30.1723 139.039C30.7616 139.039 31.2495 139.231 31.636 139.614C32.0225 139.998 32.2171 140.544 32.2198 141.251C32.2198 142.109 32.0101 142.714 31.5908 143.068C31.1714 143.422 30.6986 143.6 30.1723 143.603C29.6214 143.603 29.1431 143.42 28.7374 143.056C28.3317 142.691 28.1289 142.113 28.1289 141.321C28.1289 140.553 28.3249 139.982 28.7168 139.606C29.1088 139.231 29.594 139.042 30.1723 139.039ZM30.1723 142.994C30.5944 142.994 30.9151 142.838 31.1344 142.525C31.3537 142.213 31.4633 141.802 31.4633 141.292C31.4633 140.746 31.34 140.335 31.0933 140.058C30.8466 139.782 30.5396 139.643 30.1723 139.643C29.7968 139.643 29.4884 139.783 29.2472 140.063C29.006 140.342 28.8854 140.762 28.8854 141.321C28.8854 141.874 29.0074 142.291 29.2513 142.571C29.4953 142.85 29.8023 142.991 30.1723 142.994Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M36.9729 143.5H36.2287V140.848C36.2287 140.401 36.1493 140.094 35.9903 139.927C35.8313 139.76 35.6079 139.676 35.3201 139.676C35.1008 139.676 34.8939 139.73 34.6993 139.837C34.5047 139.944 34.3649 140.094 34.2799 140.289C34.1949 140.484 34.1538 140.759 34.1566 141.115V143.5H33.4165V139.138H34.0826V139.754H34.099C34.2552 139.516 34.4471 139.338 34.6746 139.22C34.9021 139.102 35.168 139.042 35.4722 139.039C35.7025 139.039 35.93 139.08 36.1547 139.162C36.3795 139.245 36.5549 139.368 36.681 139.532C36.8071 139.697 36.888 139.872 36.9236 140.059C36.9592 140.245 36.9757 140.497 36.9729 140.815V143.5Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M40.854 140.359C40.8211 140.12 40.7251 139.942 40.5662 139.824C40.4072 139.706 40.1838 139.646 39.896 139.643C39.6137 139.643 39.3862 139.691 39.2135 139.787C39.0408 139.883 38.9531 140.023 38.9504 140.206C38.9504 140.379 39.0189 140.502 39.1559 140.576C39.293 140.651 39.5671 140.741 39.9782 140.848C40.4387 140.966 40.7827 141.07 41.0102 141.16C41.2377 141.251 41.4159 141.377 41.5447 141.539C41.6735 141.7 41.7379 141.928 41.7379 142.221C41.7379 142.61 41.5789 142.938 41.261 143.204C40.943 143.47 40.5182 143.603 39.9864 143.603C39.4328 143.603 39.0011 143.486 38.6913 143.253C38.3816 143.02 38.1897 142.668 38.1157 142.196L38.8517 142.085C38.89 142.39 39.0024 142.617 39.1888 142.768C39.3752 142.919 39.6383 142.994 39.9782 142.994C40.3017 142.994 40.5497 142.927 40.7224 142.793C40.8951 142.658 40.9814 142.492 40.9814 142.295C40.9814 142.164 40.9417 142.058 40.8622 141.979C40.7827 141.899 40.684 141.84 40.5662 141.802C40.4483 141.763 40.1797 141.692 39.7603 141.588C39.1408 141.44 38.7324 141.26 38.5351 141.049C38.3377 140.838 38.2377 140.586 38.235 140.293C38.235 139.928 38.3802 139.628 38.6708 139.392C38.9613 139.157 39.3519 139.039 39.8425 139.039C40.3633 139.039 40.7676 139.14 41.0554 139.343C41.3432 139.546 41.5173 139.852 41.5776 140.26L40.854 140.359Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M43.699 143.5H42.959V139.138H43.699V143.5ZM43.699 138.311H42.959V137.473H43.699V138.311Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M45.9193 139.627C46.1934 139.235 46.5854 139.039 47.0952 139.039C47.6571 139.039 48.1066 139.243 48.4438 139.651C48.7809 140.06 48.9495 140.593 48.9495 141.251C48.9495 142.01 48.7617 142.591 48.3862 142.994C48.0107 143.397 47.5694 143.6 47.0623 143.603C46.5497 143.603 46.1509 143.386 45.8659 142.953H45.8494V143.5H45.1587V137.472H45.8988V139.627H45.9193ZM47.0047 142.994C47.3474 142.994 47.6297 142.847 47.8517 142.554C48.0737 142.261 48.1861 141.847 48.1888 141.312C48.1888 140.8 48.0861 140.394 47.8805 140.095C47.6749 139.797 47.3912 139.646 47.0294 139.643C46.6731 139.643 46.3866 139.798 46.1701 140.108C45.9536 140.418 45.8439 140.809 45.8412 141.284C45.8412 141.747 45.8864 142.076 45.9769 142.27C46.0673 142.465 46.203 142.635 46.3839 142.78C46.5648 142.926 46.7718 142.997 47.0047 142.994Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M50.9105 143.5H50.1704V137.473H50.9105V143.5Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M56.1609 141.506H52.8923C52.9224 141.988 53.0595 142.357 53.3034 142.612C53.5473 142.867 53.8543 142.994 54.2244 142.994C54.5067 142.994 54.7438 142.92 54.9357 142.772C55.1275 142.624 55.2742 142.398 55.3756 142.094L56.1362 142.188C56.0129 142.643 55.7867 142.993 55.4578 143.237C55.1289 143.481 54.7177 143.603 54.2244 143.603C53.5693 143.603 53.0581 143.402 52.6908 143.002C52.3235 142.602 52.1385 142.053 52.1357 141.354C52.1357 140.666 52.3139 140.108 52.6702 139.68C53.0266 139.253 53.5309 139.039 54.1833 139.039C54.5012 139.039 54.8068 139.109 55.1001 139.248C55.3934 139.388 55.6442 139.625 55.8525 139.96C56.0608 140.294 56.1636 140.809 56.1609 141.506ZM55.4002 140.897C55.3701 140.453 55.233 140.134 54.9891 139.939C54.7452 139.745 54.4765 139.646 54.1833 139.643C53.8351 139.643 53.5487 139.76 53.324 139.993C53.0992 140.226 52.969 140.527 52.9334 140.897H55.4002Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M62.106 137.444L61.9949 138.093C61.8469 138.066 61.7017 138.052 61.5591 138.052C61.381 138.052 61.2357 138.089 61.1233 138.163C61.0109 138.237 60.9534 138.425 60.9506 138.727V139.138H61.7935V139.709H60.9506V143.5H60.2106V139.709H59.5527V139.138H60.2106V138.665C60.2106 138.446 60.2311 138.247 60.2722 138.069C60.3134 137.891 60.4216 137.73 60.597 137.588C60.7725 137.445 61.0452 137.373 61.4152 137.37C61.6345 137.37 61.8647 137.394 62.106 137.444Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M64.4951 139.039C65.0844 139.039 65.5723 139.231 65.9587 139.614C66.3452 139.998 66.5398 140.544 66.5426 141.251C66.5426 142.109 66.3329 142.714 65.9135 143.068C65.4941 143.422 65.0213 143.6 64.4951 143.603C63.9441 143.603 63.4658 143.42 63.0602 143.056C62.6545 142.691 62.4517 142.113 62.4517 141.321C62.4517 140.553 62.6476 139.982 63.0396 139.606C63.4316 139.231 63.9167 139.042 64.4951 139.039ZM64.4951 142.994C64.9172 142.994 65.2379 142.838 65.4571 142.525C65.6764 142.213 65.7861 141.802 65.7861 141.292C65.7861 140.746 65.6627 140.335 65.416 140.058C65.1693 139.782 64.8624 139.643 64.4951 139.643C64.1195 139.643 63.8112 139.783 63.57 140.063C63.3288 140.342 63.2082 140.762 63.2082 141.321C63.2082 141.874 63.3301 142.291 63.5741 142.571C63.818 142.85 64.125 142.991 64.4951 142.994Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M70.0825 139.273L69.8276 139.956C69.6439 139.849 69.4644 139.796 69.289 139.796C69.0121 139.796 68.8024 139.919 68.6599 140.166C68.5174 140.412 68.4461 140.76 68.4461 141.21V143.5H67.7061V139.138H68.3803V139.796H68.3968C68.6325 139.291 68.9409 139.039 69.3219 139.039C69.574 139.039 69.8276 139.117 70.0825 139.273Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M74.6545 141.506H71.3859C71.4161 141.988 71.5531 142.357 71.7971 142.612C72.041 142.867 72.348 142.994 72.718 142.994C73.0003 142.994 73.2374 142.92 73.4293 142.772C73.6212 142.624 73.7678 142.398 73.8692 142.094L74.6299 142.188C74.5065 142.643 74.2804 142.993 73.9515 143.237C73.6225 143.481 73.2114 143.603 72.718 143.603C72.0629 143.603 71.5517 143.402 71.1844 143.002C70.8172 142.602 70.6321 142.053 70.6294 141.354C70.6294 140.666 70.8076 140.108 71.1639 139.68C71.5202 139.253 72.0246 139.039 72.6769 139.039C72.9949 139.039 73.3005 139.109 73.5938 139.248C73.887 139.388 74.1378 139.625 74.3462 139.96C74.5545 140.294 74.6573 140.809 74.6545 141.506ZM73.8939 140.897C73.8637 140.453 73.7267 140.134 73.4828 139.939C73.2388 139.745 72.9702 139.646 72.6769 139.643C72.3288 139.643 72.0424 139.76 71.8176 139.993C71.5928 140.226 71.4627 140.527 71.427 140.897H73.8939Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M78.3095 140.359C78.2766 140.12 78.1807 139.942 78.0217 139.824C77.8627 139.706 77.6394 139.646 77.3516 139.643C77.0692 139.643 76.8417 139.691 76.6691 139.787C76.4964 139.883 76.4087 140.023 76.4059 140.206C76.4059 140.379 76.4744 140.502 76.6115 140.576C76.7485 140.651 77.0226 140.741 77.4338 140.848C77.8943 140.966 78.2383 141.07 78.4658 141.16C78.6933 141.251 78.8714 141.377 79.0003 141.539C79.1291 141.7 79.1935 141.928 79.1935 142.221C79.1935 142.61 79.0345 142.938 78.7166 143.204C78.3986 143.47 77.9738 143.603 77.442 143.603C76.8883 143.603 76.4566 143.486 76.1469 143.253C75.8372 143.02 75.6453 142.668 75.5713 142.196L76.3072 142.085C76.3456 142.39 76.458 142.617 76.6444 142.768C76.8308 142.919 77.0939 142.994 77.4338 142.994C77.7572 142.994 78.0053 142.927 78.178 142.793C78.3506 142.658 78.437 142.492 78.437 142.295C78.437 142.164 78.3972 142.058 78.3177 141.979C78.2383 141.899 78.1396 141.84 78.0217 141.802C77.9039 141.763 77.6352 141.692 77.2159 141.588C76.5964 141.44 76.188 141.26 75.9907 141.049C75.7933 140.838 75.6933 140.586 75.6905 140.293C75.6905 139.928 75.8358 139.628 76.1263 139.392C76.4169 139.157 76.8075 139.039 77.2981 139.039C77.8189 139.039 78.2232 139.14 78.511 139.343C78.7988 139.546 78.9728 139.852 79.0331 140.26L78.3095 140.359Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M82.1166 143.492C81.8974 143.536 81.7123 143.558 81.5616 143.558C81.2382 143.558 81.0024 143.501 80.8544 143.389C80.7064 143.277 80.6159 143.131 80.5831 142.953C80.5502 142.775 80.5337 142.531 80.5337 142.221V139.709H79.9951V139.138H80.5337V138.052L81.2738 137.604V139.138H82.0138V139.709H81.2738V142.263C81.2738 142.482 81.2957 142.637 81.3396 142.727C81.3834 142.818 81.4985 142.864 81.6849 142.867C81.7918 142.867 81.9015 142.856 82.0138 142.834L82.1166 143.492Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M85.4345 139.273L85.1796 139.956C84.996 139.849 84.8164 139.796 84.641 139.796C84.3642 139.796 84.1545 139.919 84.012 140.166C83.8694 140.412 83.7982 140.76 83.7982 141.21V143.5H83.0581V139.138H83.7324V139.796H83.7488C83.9846 139.291 84.2929 139.039 84.6739 139.039C84.9261 139.039 85.1796 139.117 85.4345 139.273Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
        <path
          d='M89.7968 139.137L88.1275 143.574C88.1248 143.579 88.122 143.589 88.1193 143.602C87.9603 144.096 87.7753 144.496 87.5642 144.803C87.3532 145.11 87.0503 145.265 86.6556 145.268C86.524 145.268 86.3637 145.24 86.1746 145.185L86.1006 144.486C86.2431 144.53 86.3829 144.552 86.5199 144.552C86.794 144.552 86.9832 144.477 87.0873 144.326C87.1915 144.175 87.3107 143.903 87.445 143.508L85.7881 139.137H86.5857L87.6136 141.995C87.6739 142.17 87.7383 142.396 87.8068 142.673H87.8233C87.8918 142.355 87.9795 142.052 88.0864 141.765L89.0444 139.137H89.7968Z'
          fill='#536063'
          stroke='#536063'
          strokeWidth='0.657835'
        />
      </g>
      <defs>
        <clipPath id='clip0_495_27369'>
          <rect width='100' height='149' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}
